<template>
  <base-modal-view title="Request Offsite Appraisal" @cancel="cancelClicked">
    <form @submit.prevent="submitClicked" class="flex-column">
      <p class="h5 no-margin margin-top">Customer</p>
      <p class="text-field-title margin-top-05 margin-bottom-025">First Name:</p>
      <input v-model="first_name" type="text" class="text-field" placeholder="Customer Name" />
      <p class="text-field-title margin-top-05 margin-bottom-025">Last Name:</p>
      <input v-model="last_name" type="text" class="text-field" placeholder="Customer Name" />
      <p class="text-field-title margin-top-05 margin-bottom-025">Mobile Number:</p>
      <input v-model="creationDict.customer_work_phone" class="text-field" type="text" placeholder="Customer Phone" />
      <p class="text-field-title margin-top-05 margin-bottom-025">Email:</p>
      <input v-model="creationDict.customer_email" class="text-field" type="email" placeholder="Customer Email" />
      <p class="h5 no-margin margin-top">Dealership</p>
      <p class="text-field-title margin-top-05 margin-bottom-025">Department:</p>
      <select class="dropdown-field" v-model="creationDict.department" v-if="currentDealership.departments">
        <option value="">-- Select a Department --</option>
        <option v-for="department in currentDealership.departments" v-bind:key="department.url" :value="department.url">
          {{ department.title }}
        </option>
      </select>

      <primary-button :disabled="inputError()" type="submit" class="margin-top" title="Request Offsite Appraisal" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapState } from "vuex";

export default {
  name: "CreateOffsiteFormModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      first_name: "",
      last_name: "",
      creationDict: {
        customer_name: "",
        customer_work_phone: "",
        customer_email: "",
        department: ""
      }
    };
  },
  methods: {
    submitClicked: function () {
      if (this.inputError()) {
        return;
      }
      this.creationDict.customer_name = `${this.first_name} ${this.last_name}`;
      this.$emit("createOffsite", this.creationDict);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    inputError: function () {
      return !(
        this.first_name &&
        this.last_name &&
        this.creationDict.customer_work_phone &&
        this.creationDict.customer_email
      );
    }
  },
  computed: {
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
